import { NotProgressedStatusSf, SubmissionStatusSf } from './application';
import { UserRole } from './status';

import type { AvailabilityStatus, DisplayStatus, PrelimState } from 'utils/api/application';
import type { SectionStatus } from 'utils/api/section-status';
import type { ApiResponse } from 'utils/api/types';
import CONSTANTS from 'utils/constants';
import fetchWithToken from 'utils/fetchers';

export type Currencies = (typeof CONSTANTS.CURRENCIES)[number];
export type OrganisationStatus = 'NOT_STARTED' | 'VERIFIED' | 'UNVERIFIED' | 'REJECTED';
export type AccountProfileStatus = 'COMPLETE' | 'NOT_COMPLETE';
export type PositiveActionStatus = 'NotStarted' | 'InProgress' | 'Complete';
export type AcceleratorAwardsStatus = 'NotStarted' | 'InProgress' | 'Complete';

export type PageGroupPage = {
  id: string;
  title: string;
  status: SectionStatus;
};

export type Participant = {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  email: string;
  participantStatus: 'ACTIVE' | 'INVITED';
  role: UserRole;
  title?: string;
  userId?: string;
  invitationCode?: string;
  accountProfileStatus: AccountProfileStatus;
};

export type FormFileDetails = {
  fileId: string;
  fileName: string;
  fileSize: number;
  lastModifiedDate: string;
};

export type ParticipantLimits = {
  [key in Exclude<UserRole, 'LEAD_APPLICANT' | 'GUEST_EDITOR'>]: {
    max: number;
    min: number;
  };
};

type FormPageGroup = {
  id: string;
  title: string;
  pages?: PageGroupPage[];
};

export type StaticFormId = 'pa' | 'aa' | null;

export type OrgStatus = Exclude<OrganisationStatus, 'NOT_STARTED'>;

export type ApplicationOverview = {
  applicationId: string;
  applicationProgress: number;
  applicationDeadline: string;
  awardLetterSentDate: string;
  canBeDeferred: boolean;
  dataAvailability: AvailabilityStatus;
  grantReferenceNumber: string;
  applicationTitle: string;
  currency: string;
  currentUserRole: UserRole | 'RESEARCH_OFFICE';
  schemeTitle: string;
  roundDisplayName: string;
  schemeUrl: string;
  submissionStatus: SubmissionStatusSf;
  notProgressedStatus: NotProgressedStatusSf;
  submissionDate: string;
  blankFormDownloadUrl?: string;
  completedApplicationForm?: FormFileDetails;
  organisation?: {
    organisationId: string;
    status: OrgStatus;
    name: string;
  };
  participants: Participant[];
  participantLimits: ParticipantLimits;
  displayStatus: DisplayStatus;
  timeline: {
    title: string;
    dateOrDateRange: string;
    details: string;
  }[];
  form?: {
    pageGroups: FormPageGroup[];
  };
  staticForm?: {
    pageGroups: FormPageGroup[];
  } | null;
  fileUploadLimit: number;
  prelimCompletedApplicationForm: FormFileDetails | null;
  prelimBlankFormDownloadUrl: FormFileDetails | null;
  prelimState: PrelimState;
  schemeGuidanceUrl: string;
  staticFormId: StaticFormId;
  grantStartDate: string | null;
  grantEndDate: string | null;
};

export const getApplicationOverview = async (id: string) => {
  const response: ApiResponse<ApplicationOverview, null> = await fetchWithToken(
    `api/${CONSTANTS.API_VERSION}/form/${id}/view/overview`
  );
  return response;
};

export default getApplicationOverview;
