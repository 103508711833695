import type { ApiResponse } from 'utils/api/types';
import CONSTANTS from 'utils/constants';
import fetchWithToken, { fetchFromApi, getCookieByName } from 'utils/fetchers';

export type User = {
  firstName: string;
  lastName: string;
  email: string;
  id: string;
  isResearchOfficeUser: boolean;
  organisation: string;
  hasExpertReviews?: boolean;
  hasCommittees?: boolean;
};

export type UserRole = 'LEAD_APPLICANT' | 'CO_APPLICANT' | 'SPONSOR' | 'GUEST_EDITOR';

export type PlannedDowntime = {
  start: string;
  end: string;
};

export type UserOrganisation = {
  organisationId: string;
  name: string;
  role: string;
};

export type CurrentUser = User & {
  isResearchOfficeUser: boolean;
  organisation?: UserOrganisation;
  isSupportSession: boolean;
};

export type Statuses = 'UP' | 'DOWN_PLANNED' | 'DOWN_UNPLANNED';

export type Status = {
  status: Statuses;
  plannedDowntime: PlannedDowntime | null;
  user: CurrentUser;
};

export const getStatus = async () => {
  const token = getCookieByName('token');

  let response: ApiResponse<Status>;

  if (token) {
    response = await fetchWithToken(`api/${CONSTANTS.API_VERSION}/status`);
  } else {
    response = await fetchFromApi(`api/${CONSTANTS.API_VERSION}/status`);
  }

  return response;
};

export default getStatus;
