import dayjs, { Dayjs, ManipulateType } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import quarterOfYear from 'dayjs/plugin/quarterOfYear'; // Import the quarterOfYear plugin
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import pluralise from './pluralise';
import reportError from './report-error';

dayjs.extend(quarterOfYear); // Extend Day.js with the plugin

/**
 * Add plugins to dayjs:
 * isBetween  (https://day.js.org/docs/en/plugin/timezone)
 * timezone   (https://day.js.org/docs/en/plugin/timezone)
 * utc        (https://day.js.org/docs/en/plugin/utc)
 */
dayjs.extend(isBetween);
dayjs.extend(timezone);
dayjs.extend(utc);

export type CustomFormatOptions = {
  timeZone?: string;
  weekday?: 'narrow' | 'short' | 'long';
  year?: 'numeric' | '2-digit';
  month?: 'numeric' | '2-digit' | 'narrow' | 'short' | 'long';
  day?: 'numeric' | '2-digit';
  hour?: 'numeric' | '2-digit';
  minute?: 'numeric' | '2-digit';
  second?: 'numeric' | '2-digit';
  hour12?: boolean;
};

type DateFormatOptions = {
  locale?: string;
  timeZone?: string;
  type?: 'date' | 'time' | 'datetime';
  customFormat?: CustomFormatOptions;
  showUkDaylightSavingsSuffix?: boolean;
  fallback?: string;
};

/**
 * Standardise our date format so that we have a consistent date format across the app.
 * Will accept options which control the locale and timezone, so that we can display
 * some times relevant to the user's locale, and others relevant to the business'
 * locale (e.g. deadlines).
 *
 * Setting `showUkDaylightSavingsSuffix` to true will add
 * either GMT or BST as appropriate as suffix to the rest of the date string.
 *
 * Setting the `type` option replicates using the timeStyle (set to short) and dateStyle
 * (set to long).
 *
 * Alternatively, a customFormat can be provided - this allows greater customisation of
 * the returned format using standard formatting options for Intl.DateTimeFormat.
 *
 */
export const formatDateTime = (date: string | number | Date, options?: DateFormatOptions) => {
  const fallback = options?.fallback || 'Unknown';

  if (!date) return fallback;

  const dateObj = new Date(date);

  const type = options?.type || 'datetime';
  const locale = options?.locale || 'en-GB';

  let standardDateFormatter = {};

  /**
   * If we only want to return time OR date, these settings will prevent the formatter
   * from returning the part we don't want.
   */
  switch (type) {
    case 'date':
      standardDateFormatter = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        timeZone: options?.timeZone || 'UTC',
      };
      break;
    case 'time':
      standardDateFormatter = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: options?.timeZone || 'UTC',
      };
      break;
    default:
      standardDateFormatter = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: options?.timeZone || 'UTC',
      };
  }

  const formatter = new Intl.DateTimeFormat(
    locale,
    options?.customFormat ? options.customFormat : standardDateFormatter
  );

  try {
    /*
     * If we set month to long, the formatter uses the word 'at' in the returned string.
     * As far as I can see, this isn't customiseable - so we replace it with a comma if present.
     */
    const formattedDate = formatter.format(dateObj).replace(/ at /g, ', ');

    if (!options.showUkDaylightSavingsSuffix) {
      return formattedDate;
    }

    const suffix = isBST(new Date(date)) ? 'BST' : 'GMT';
    // Non breaking space between time and suffix.
    return `${formattedDate}\u00A0${suffix}`;
  } catch (e) {
    reportError(e);

    return fallback;
  }
};

/**
 * A wrapper around the `Dayjs.diff` function. Performs all diff calculations relative to UK time
 * for consistent output across timezones.
 */
export const getUKDiffBetweenDates = (
  date1: Parameters<Dayjs['diff']>[0],
  date2: Parameters<Dayjs['diff']>[0],
  unit: Parameters<Dayjs['startOf']>[0],
  countFromStartOfUnit = true
): number => {
  /*
   * We currently display all dates in UK time, which means that date diff calculations
   * should also be done in UK time for consistency.
   *
   * If we didn't do this, dayjs would perform diff calculations relative to local time,
   * resulting in inconsistencies between the dates we display to users (which are always in UK time)
   * and their corresponding diff values (which would be in local time).
   */
  let date1UK = dayjs(date1).utc().set('milliseconds', 0);
  let date2UK = dayjs(date2).utc().set('milliseconds', 0);

  if (countFromStartOfUnit) {
    date1UK = date1UK.startOf(unit);
    date2UK = date2UK.startOf(unit);
  }

  const diffInDays = date2UK.diff(date1UK, unit);

  return diffInDays;
};

/**
 * Returns a countdown to a given date in the specified `units` (relative to UK time).
 * `date` should be an ISO string
 */
export const getUKCountdownUntilDate = (
  date: Parameters<Dayjs['diff']>[0],
  unit: Parameters<Dayjs['startOf']>[0],
  countFromStartOfUnit = true
): number => getUKDiffBetweenDates(new Date().toISOString(), date, unit, countFromStartOfUnit);

/**
 * Calculates the duration from today to a specified date and returns it as a formatted string.
 *
 * @param {string} dateToCheck - The date to calculate the duration from today.
 * @param {string} endDate - The end date to check if it has passed.
 * @param {boolean} [showYears=false] - Optional parameter to include years in the duration.
 * @returns {string|null} The formatted duration string or null if the end date has passed.
 * Returns 'Today' if the date is between dateToCheck and and endDate, otherwise returns the number of days, weeks or years.
 * Returns null if today is after the endDate.
 */

export const getDurationFromTodayText = (dateToCheck: string, endDate: string, showYears: boolean = false): string => {
  const diffInDays = getUKCountdownUntilDate(dateToCheck, 'day');
  const diffInWeeks = getUKCountdownUntilDate(dateToCheck, 'week', false);
  const diffInYears = getUKCountdownUntilDate(dateToCheck, 'year', false);

  const hasEndDatePassed = getUKCountdownUntilDate(endDate, 'day') < 0;

  if (hasEndDatePassed) {
    return null;
  }

  if (diffInDays <= 0) {
    return 'Today';
  }

  if (diffInWeeks < 1) {
    return `${diffInDays} ${pluralise('day', diffInDays)}`;
  }

  if (diffInDays >= 7 && diffInDays <= 56) {
    return `${diffInWeeks} ${pluralise('week', diffInWeeks)}`;
  }

  if (showYears) {
    if (diffInYears <= 0) {
      return `${Math.floor(diffInWeeks)} ${pluralise('week', Math.floor(diffInWeeks))}`;
    }

    return `${diffInYears} ${pluralise('year', diffInYears)}`;
  }

  return '';
};

const formatDates = (dateToFormat: string, removeDay?: boolean, removeMonth?: boolean, removeYear?: boolean): string =>
  formatDateTime(dateToFormat, {
    customFormat: {
      ...(!removeDay && { day: 'numeric' }),
      ...(!removeMonth && { month: 'long' }),
      ...(!removeYear && { year: 'numeric' }),
      timeZone: 'Europe/London',
    },
  });

export const generateDateRangeText = (
  startDate: string,
  endDate: string,
  rangeSeparator = '-',
  removeDay = false
): string => {
  const isSameDay = getUKDiffBetweenDates(startDate, endDate, 'day') === 0;
  const isSameMonth = getUKDiffBetweenDates(startDate, endDate, 'month') === 0;

  // if start day is the same as end day, show the full date (e.g. 1 July 2021)
  if (isSameDay || (isSameMonth && removeDay)) {
    return formatDates(startDate, removeDay);
  }

  // if start day and end day are in the same month, we don't need to show the month & year twice (e.g. 1-2 July 2021)
  if (isSameMonth) {
    return `${formatDates(startDate, false, true, true)}-${formatDates(endDate)}`;
  }

  // show full day month and year (e.g. 1 July 2021 - 2 August 2021)
  return `${formatDates(startDate, removeDay)} ${rangeSeparator} ${formatDates(endDate, removeDay)}`;
};

/**
 * Checks if a date should be displayed as BST or GMT
 */
const lastSunday = (month: number, year: number): Date => {
  const d = new Date();
  const lastDayOfMonth = new Date(Date.UTC(year || d.getFullYear(), month + 1, 0));
  const day = lastDayOfMonth.getDay();
  return new Date(Date.UTC(lastDayOfMonth.getFullYear(), lastDayOfMonth.getMonth(), lastDayOfMonth.getDate() - day));
};

export const isBST = (date: Date): boolean => {
  const d = date || new Date();
  const starts = lastSunday(2, d.getFullYear());
  starts.setUTCHours(1);
  const ends = lastSunday(9, d.getFullYear());
  ends.setUTCHours(1);

  return d.getTime() >= starts.getTime() && d.getTime() < ends.getTime();
};

/**
 * The award acceptance deadline is 1 year from the date Wellcome sent the award notification. Format this correctly.
 */
export const calculateAwardAcceptanceDeadlineFromLetterSentDate = (awardLetterSentDate: string): string => {
  const date = new Date(awardLetterSentDate);
  date.setFullYear(date.getFullYear() + 1);

  return formatDateTime(date, { type: 'date' });
};

/**
 * The award acceptance deadline is 3 months from the transferDate. Format this correctly.
 */
export const calculateAwardAcceptanceDeadlineFromTransferDate = (transferDate: string): string => {
  const date = new Date(transferDate);
  date.setMonth(date.getMonth() + 3);

  return formatDateTime(date, { type: 'date' });
};

/**
 * If the deadline is today, returns the text 'Today'.
 * If the deadline has passed, returns how many days have passed since the deadline.
 * If the deadline is in the future, returns how the deadline compares to the meeting start date/end date:
 *    1. If the deadline is before the start date or after the end date, returns the number of days between the deadline and the start/end date.
 *    2. If the deadline is between the start date and the end date, returns an empty string.
 */
export const getCommitteeTimeDifferenceText = (
  deadlineISO: string,
  meetingStartISO: string,
  meetingEndISO: string,
  relativeToTodayAfterDeadline: boolean = true
) => {
  const isDeadlineBeforeMeetingStartDate = getUKDiffBetweenDates(deadlineISO, meetingStartISO, 'day') > 0;
  const isDeadlineAfterMeetingEndDate = getUKDiffBetweenDates(deadlineISO, meetingEndISO, 'day') < 0;
  const isDeadlineBeforeToday = getUKCountdownUntilDate(deadlineISO, 'day') < 0;
  const isDeadlineToday = getUKCountdownUntilDate(deadlineISO, 'day') === 0;

  let timeDiffText: string;
  if (isDeadlineToday && relativeToTodayAfterDeadline) {
    timeDiffText = 'Today';
  } else if (isDeadlineBeforeToday && relativeToTodayAfterDeadline) {
    timeDiffText = `${getRemainingDaysUntilDate(dayjs().toISOString(), deadlineISO)} ago`;
  } else if (isDeadlineBeforeMeetingStartDate) {
    timeDiffText = `${getRemainingDaysUntilDate(meetingStartISO, deadlineISO)} before meeting`;
  } else if (isDeadlineAfterMeetingEndDate) {
    timeDiffText = `${getRemainingDaysUntilDate(deadlineISO, meetingEndISO)} after meeting`;
  }

  return timeDiffText;
};

const getRemainingDaysUntilDate = (startDate: string, date: string): string => {
  const diffInDays = getUKDiffBetweenDates(date, startDate, 'day');

  if (diffInDays <= 0) return '';
  return `${diffInDays} ${pluralise('day', diffInDays)}`;
};

/**
 * Checks that today's date is within the month before a given end date
 */
export const isTodayWithinTimePeriodBeforeDate = (
  endDateString: string,
  timeLength: number,
  timeUnit: ManipulateType
) => {
  const endDate = dayjs(endDateString).utc();
  const startDate = endDate.subtract(timeLength, timeUnit);

  // Not inclusive of the start date but is inclusive of the end date
  return dayjs().utc().isBetween(startDate, endDate, 'day', '(]');
};

export function getNextQuarterStartDate(date: string | Date) {
  const today = dayjs(date);
  const nextQuarterStart = today.add(1, 'quarter').startOf('quarter');

  return nextQuarterStart.format('DD MMMM YYYY');
}

export function getCurrentQuarterPeriod(date: string | Date) {
  const today = dayjs(date);
  const currentQuarterStart = today.startOf('quarter');
  const currentQuarterEnd = currentQuarterStart.endOf('quarter');
  return `${currentQuarterStart.format('DD MMMM YYYY')} and ${currentQuarterEnd.format('DD MMMM YYYY')}`;
}
