import URL_LIBRARY from './url-library';

export const CONSTANTS = {
  ACCOUNT_PROFILE_SCHEMA_ID: 'accountprofilev02',
  /**
   * This is an exhaustive list of all error codes which the middleware API
   * can return.
   *
   * @todo Add link to further documentation
   */
  API_ERROR_CODES: {
    cannotParseJson: 'CANNOT_PARSE_JSON',
    committeeConfidentialityAgreementRequired: 'CONFIDENTIALITY_AGREEMENT_REQUIRED',
    databaseError: 'DATABASE_ERROR',
    fileSizeTooLarge: 'FILE_TOO_LARGE',
    invalidAccountProfileStatus: 'INVALID_ACCOUNT_PROFILE_STATUS',
    invalidApiUrl: 'INVALID_API_URL',
    invalidApplicationId: 'INVALID_APPLICATION_ID',
    invalidCsvColumnCount: 'INVALID_CSV_COLUMN_COUNT',
    invalidCsvRowCount: 'INVALID_CSV_ROW_COUNT',
    invalidCsvInconsistentColumns: 'INVALID_CSV_INCONSISTENT_COLUMNS',
    invalidCsvInvalidCharacter: 'INVALID_CSV_INVALID_CHARACTER',
    invalidEncoding: 'INVALID_ENCODING',
    invalidField: 'INVALID_FIELD',
    invalidFileColumns: 'INVALID_FILE_COLUMNS',
    invalidFileFormat: 'INVALID_FILE_FORMAT',
    invalidFileUploadBody: 'INVALID_FILE_UPLOAD_BODY',
    invalidFileUploadHeader: 'INVALID_FILE_UPLOAD_HEADER',
    invalidLockToken: 'INVALID_LOCK_TOKEN',
    invalidRepeatedPassword: 'INVALID_REPEATED_PASSWORD',
    invalidReqBodyStructure: 'INVALID_REQUEST_BODY_STRUCTURE',
    invalidRoundId: 'INVALID_ROUND_ID',
    invalidRow: 'INVALID_ROW',
    invalidSchemeId: 'INVALID_SCHEME_ID',
    invalidSectionId: 'INVALID_SECTION_ID',
    invalidSectionStatus: 'INVALID_SECTION_STATUS',
    invalidSubmisionStatus: 'INVALID_SUBMISSION_STATUS',
    invalidUrlField: 'INVALID_URL_FIELD',
    invalidValidationToken: 'INVALID_VALIDATION_TOKEN',
    lockExpired: 'LOCK_EXPIRED',
    missingCoapplicants: 'MISSING_COAPPLICANTS',
    missingOrganisation: 'MISSING_ORGANISATION',
    missingSponsors: 'MISSING_SPONSORS',
    notAuthorized: 'NOT_AUTHORIZED',
    notFound: 'NOT_FOUND',
    participantIsLeadApplicant: 'PARTICIPANT_IS_LEAD_APPLICANT',
    participantIsCoApplicant: 'PARTICIPANT_IS_CO_APPLICANT',
    participantIsGuestApplicant: 'PARTICIPANT_IS_GUEST_EDITOR',
    participantIsSponsorApplicant: 'PARTICIPANT_IS_SPONSOR',
    participantIsSelfApplicant: 'PARTICIPANT_IS_SELF',
    passwordContainsUsername: 'PASSWORD_CONTAINS_USERNAME',
    reqError: 'REQUEST_ERROR',
    roundIsClosed: 'ROUND_IS_CLOSED',
    salesforceError: 'SALESFORCE_ERROR',
    sectionIsLocked: 'SECTION_IS_LOCKED',
    sendEmailError: 'SEND_EMAIL_ERROR',
    subschemaNotAllowed: 'SUBSCHEMA_NOT_ALLOWED',
    unhandled: 'UNHANDLED',
    unknownSalesForceError: 'UNKNOWN_SALESFORCE_ERROR',
    unsupportedApiVersion: 'UNSUPPORTED_API_VERSION',
    userInactive: 'USER_INACTIVE',
    userNotAllowed: 'USER_NOT_ALLOWED',
  },
  API_VERSION: process.env.NEXT_PUBLIC_MIDDLEWARE_API_VERSION,
  API_TOKEN_EXPIRY: 23.75 * 60 * 60 * 1000, // almost 1 day (ms)
  COOKIES: {
    TRACKING_COOKIE: {
      NAME: 'CookieControl',
      MAX_AGE: 31536000,
      PATH: '/',
    },
  },
  COUNTDOWN_THRESHOLD_FOR_APPLICATION_DEADLINE: 30,
  CURRENCIES: ['GBP', 'USD', 'EUR'],
  // Technically we can save dates as early as 1700. But things get squirrelly in JS pre-gregorian calendar - I've set 1900 as the cutoff...
  DATE_MIN: -2208988800000, // Mon Jan 01 1900 00:00:00 GMT+0000
  DATE_MAX: 32535215940000, // Wed Dec 31 3000 23:59:00 GMT+0000
  DEADLINE_DATE_FORMAT: 'YYYY-MM-DD hh:mm:ss',
  DEFAULT_FORM_ROOT: 'fundingPlatformForm',
  DEBOUNCE_TIME: 500,
  EMAIL_PATTERN: '[^@\\s]+@[^@\\s]+\\.[^@\\s]+',
  EMAIL_MAX_LEN: 66,
  GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
  HIDDEN_FIELDS_TO_SAVE_DEFAULT_ON_LOAD: <string[]>['CurrentPosition'],
  LATEST_SCHEMA_ID: 'earlycareerawardprototypev06',
  MAX_NUMBER_LENGTH: 16,
  NUMBERS_AS_WORDS: [
    'zero',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen',
    'twenty',
  ],
  ROLES: {
    CO_APPLICANT: 'Coapplicant',
    LEAD_APPLICANT: 'Lead applicant',
    SPONSOR: 'Sponsor',
    GUEST_EDITOR: 'Guest editor',
    RESEARCH_OFFICE: 'Administering organisation',
  },
  ROLE_GUIDANCE_URL: {
    CO_APPLICANT: URL_LIBRARY.WELLCOME_MAIN_SITE.CO_APPLICANTS_GUIDANCE,
    LEAD_APPLICANT: URL_LIBRARY.WELLCOME_MAIN_SITE.LEAD_APPLICANT_GUIDANCE,
    SPONSOR: URL_LIBRARY.WELLCOME_MAIN_SITE.SPONSORS_GUIDANCE,
  },
  ANNUAL_REPORT_ROLES: {
    REPORT_GUEST_EDITOR: 'Guest editor',
  },
  RESEARCH_OFFICE_ROLES: {
    ADMIN: 'Admin',
  },
  STATUSES: {
    INVITEE: {
      ACTIVE: 'Accepted',
      CANCELLED: 'Cancelled',
      DECLINED: 'Declined',
      INVITED: 'Not accepted yet',
      NO_STATUS: '',
      REMOVED: 'Removed',
    },
  },
  SCHEMA_NULL_TYPES: {
    ACCORDION: 'accordion',
  },
  SCHEMA_OBJECT_TYPES: {
    FLEX_ITEM: 'flexItem',
    ARRAY_ITEM: 'arrayItem',
    DEPENDENCY: 'dependency',
    FIELDSET: 'fieldset',
    DETAILS: 'details',
  },
  SCHEMA_ARRAY_TYPES: {
    FLEX_TABLE: 'flexTable',
  },
  STATIC_FORM_SECTIONS: {
    PositiveActionInformation: {
      staticFormId: 'pa',
      dataCy: 'positive-action-link',
      currentText: 'Information for positive action',
      pillContext: 'positiveAction',
    },
    AcceleratorAwardInformation: {
      staticFormId: 'aa',
      dataCy: 'accelerator-awards-link',
      currentText: 'Before you start',
      pillContext: 'acceleratorAwards',
    },
  },
  TIMEZONE: 'Europe/London',
} as const;

export default CONSTANTS;
